import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Tooltip } from '@mui/material'
import React, {useState} from 'react'
import MapsUgcIcon from '@mui/icons-material/MapsUgc';
import CommentModal from './CommentModal';

const AddComment = (props) => {

   const [ commentClick, setCommentClick ] = useState(false)
  
  return (
    <Tooltip title='leave a comment' open={!commentClick}
      className='blink'
    >
      <Box 
        onClick={()=>{
          setCommentClick(true)
        }}
      sx={{cursor:'pointer',width:'2rem',
        height:'2rem', borderRadius:'50px 50px 50px 10px',
        justifyContent:'center', 
        alignItems:'center',alignContent:'center',
        padding:'0.5rem', zIndex:999,
        boxShadow:'0 0 3px 2px rgba( 0, 0, 0, 0.1), inset 0 0 3px 1px rgba( 0, 0,0,0.3)',
        border:'2px solid #757575',
        '&:hover': {background:'rgba(144, 254, 195,0.5)'},
        '&:active': {boxShadow:'inset 0 0 3px 2px rgba( 0, 0, 0, 0.1)'},
    
      }}>
          {/* &nbsp; */}
        
        <MapsUgcIcon className='button-woddle'/>
              {/* <FontAwesomeIcon  className='button-woddle'
              icon={'faCommentMedical'} /> */}
      </Box>
    </Tooltip>
   

  )
}

export default AddComment