import ContactPhone from '@mui/icons-material/ContactPhone'
import { Container, Modal,Box, Button, Stack , Input, Typography, TextField } from '@mui/material'
import React, { useState } from 'react'
import CloseButton from '../../../components/CloseButton'
import Copyright from '../../../components/Copyright';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';



const LockoutModal = (props) => {
    const { setLockoutModal, lockoutModal, lock } = props
    const [ email, setEmail ] = useState('')
    const [ actionError, setActionError ] = useState('')
    const [ hideCopy, setHideCopy ] = useState(false)
    
    const colors ={
    main:'#858585',
    accent: '#000',
  }
  return (
    <>
        <Modal 
            // open={LockoutModal}
            open={lock}
            onClose={()=>setLockoutModal(!lockoutModal)}
            >
            <Container sx={{zIndex:999, fontFamily:'Gilroy, sans-serif'}}>
                <Box sx={{ 
                    display:'flex',
                    flexDirection:'column',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '100%',
                    height:'100%',

                    backgroundColor:'#000',
                    // backgroundImage:'url("/assets/Gradient_01_Frame2.png")',
                    backgroundAttachment:'fixed',
                    backgroundRepeat:'no-repeat',
                    borderRadius:'10px',
                    border:'2px solid #000',
                    fontFamily: 'Gilroy, sans-serif',
                    justifyContent:'center',
                    textAlign:'center',
                    alignItems:'center',
                    gap:'0.5rem',
                    padding:'0.5rem'
                    
                    }}>
                        {/* <Box sx={{position:'absolute', top:'0.5rem', right:'0.5rem'}}>
                            <CloseButton func={setLockoutModal} val={LockoutModal}
                        
                            />
                        </Box> */}
                        <h2 style={{color:'#FFF'}}>Welcome to</h2>
                       <Stack sx={{display:'flex',flexDirection:'column',height:'300px',width:'fit-content',
                       alignItem:'center', marginBottom:'2rem',
                        position:'relative', //border:'1px solid red'
                        }}>
                            <Box component={'img'}
                                    className='lock-logo'
                                    src={`/assets/Rex_Entropy_Icon_Glow.png`}
                                    sx={{width:'100%', 
                                    maxWidth:'250px',
                                    height:'auto', 
                                    // opacity:'0.5',
                                    border:'3px solid rgba( 0,0 ,0,0.5)',
                                    borderRadius:'50%',
                                    backdropFilter:'blur(5px)',
                                    backgroundColor:'#000',
                                    boxShadow:'0 0 15px 5px rgba( 0,0, 0,0.2), inset 0 0 10px 3px rgba( 255, 255,255,0.3)',
                                    position:'relative',
                                    zIndex:0,
                                   
                                    }}
                                        ></Box>
                                    
                                <div style={{ 
                                    // position:'absolute',
                                    bottom:'1rem',
                                    // left:'80%',
                                    // transform:'',
                                    margin:'auto',
                                    transform:'scale(2,2) translate(0%,0)',
                                    display:'flex',color:'#000' ,
                                    backgroundColor:'#FFF',fontFamily:'Gilroy,sans-serif',
                                    width:'fit-content',
                                    boxShadow:'0 0 10px 4px rgba( 255, 255, 255, 0.3), inset 0 0 5px 1px rgba( 0, 0,0,0.3)',
                                    border:'2px solid #000', borderRadius:'20px',padding:'0.5rem' }}>
                                <b style={{ position:'absolute',left:'2rem', top:'0.25rem'}}>
                                    The 
                                </b>
                                <h1>RexPlayer</h1>
                                </div>
                            </Stack>
                     
                       
                       {/* <span style={{ position:'relative',display:'flex',color:'#FFF' , border:'2px solid #FFF', borderRadius:'20px',padding:'0.5rem' }}>
                            <b style={{ position:'absolute',left:'2rem', top:'0.25rem'}}>
                             The 
                            </b>
                            <h1>RexPlayer</h1></span>
                        <Box component={'img'}
                        className='lock-logo'
                        src={`/assets/Rex_Entropy_Icon_Glow.png`}
                        sx={{width:'100%', 
                            maxWidth:'250px',
                            height:'auto', 
                            opacity:'0.5',
                            border:'3px solid #000',
                            borderRadius:'50%',
                            backdropFilter:'blur(5px)',
                            backgroundColor:'#000',
                            boxShadow:'0 0 10px 4px rgba( 255,255, 255,0.2)'
                            // position:'absolute'
                        }}
                        >
                        </Box> */}
                        <Box sx={{
                            display:'flex',
                            justifyContent:'center',
                            alignItems:'center',
                            marginTop:'0.5rem',
                            // position:'absolute',
                            borderRadius:'10px',
                            border:'3px solid #000',
                            backgroundColor:'rgba( 255,255,255,1)',
                            // backdropFilter:'blur(3px)',
                            boxShadow:'0px 0px 20px 4px rgba( 255,255,255,0.2)',
                            width:'300px',
                            height:'200px',
                            // left:'50%', top:'60%', 
                            // transform:'translate(-50%,0%)'
                            }}>
                        
                            <Stack sx={{ gap:'0.5rem', width:'100%',justifyContent:'center',
                                alignItems:'center'
                            }}>
                                <h4>Please enter your email to continue</h4>
                                <TextField sx={{wdith:'90%'}} onChange={(e)=>{setEmail(e.target.value)}}/>
                                <Button variant='outlined' onClick={()=>{
                                    if(email){
                                        window.location.assign(`/music/${email}`)
                                    
                                    }else{setActionError('Email required')}

                                }}>
                                    submit
                                </Button>
                            </Stack>

                        </Box>
                        
                    </Box>
                    <Box sx={{
                        display:'flex',
                        flexDirection:'column',
                        position:'fixed',
                        bottom:'0',
                        left:'50%',
                        transform:'translate(-50%,20%)',
                        width:'50%',
                        minWidth:'350px',
                        maxWidth:'450px',
                        height:hideCopy? '0':'8.5rem'
                        }}>

                <Box sx={{position:'relative',alignSelf:'start',width:'100%', }} onClick={()=>{
                setHideCopy(!hideCopy)
                }}>

                {!hideCopy ? 
                <Box sx={{position:'absolute',left:'0.5rem',top:'0.5rem', zIndex:999}}>
                    <CloseButton /> 
                </Box>
                : <Box sx={{
                    display:'flex',
                    position:'absolute',
                    bottom:'-2px',
                    left:'50%',
                    transform:'translate(-50%,0)',
                    border:'2px solid #858585',
                    padding:'0.25rem 1.25rem',
                    backgroundColor:'#fff',cursor:'pointer',
                    alignItems:'center',
                    boxShadow: '0px -1px 7px 2px rgba(0,0,0,0.2)',
                    borderRadius:'5px 5px 0px 0px'}}>
                    <ArrowDropUpIcon />
                    about us
                    </Box>}
                </Box>
                
                {!hideCopy && <Copyright view='signupSheet' 
                hide={hideCopy}
                color={colors.main}
                shadow={true}
                /> }
                </Box>
                    </Container>
                </Modal>
        
    </>
  )
}

export default LockoutModal