import { Box, Container, Stack, Tooltip, Typography,
  List, ListItem, ListItemText,
  IconButton,
 } from '@mui/material'
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import React,{useEffect, useState, useRef} from 'react'
import { useParams } from 'react-router-dom';
import Playlist from './Playlist';
import { background, MenuDescendantsProvider } from '@chakra-ui/react';
import Copyright from '../../../components/Copyright';
import useWindowDimensions from "../../../hooks/windowDimensions";
import InfoIcon from '@mui/icons-material/Info';
import CloseButton from '../../../components/CloseButton';
import ShareIcon from '@mui/icons-material/Share';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ShoppingCart from '@mui/icons-material/ShoppingCart';
import PinModal from './PinModal';
import ShareModal from './ShareModal';
import ZoomInIcon from '@mui/icons-material/ZoomIn';

// import albums from '../../../utils/albumList'
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Info from '@mui/icons-material/Info';
import ZoomModal from './ZoomModal';
import * as API from '../../../utils/APICalls'
import LockoutModal from './LockoutModal';
import UpdateModal from './UpdateModal';
import AddComment from './AddComment';
import CommentModal from './CommentModal';


  const ShopMusicPlayer = (props) => {
  
    const  albums = props.albums
    // const email = useParams().email?.toString()
    const { trackId , email } = useParams()
    const [isLoading, setIsLoading] = useState(true);
    const [isDownloadable, setIsDownloadable] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [ timestamp, setTimestamp ] = useState(0)
    const audioRef = useRef();
    const [ isMuted, setIsMuted ] = useState(false)
    const [ listener, setListener ] = useState({})
    const [ userLocation, setUserLocation ] = useState(false)
    const [ zoomModalOpen, setZoomModalOpen ] = useState(null)
    const [ updateModalOpen, setUpdateModalOpen ] = useState(false)
    const [ pinModalOpen, setPinModalOpen ] = useState(false)
    const [ shareModalOpen, setShareModalOpen ] = useState(false)
    const [commentModalOpen,setCommentModalOpen ] = useState(false)
    const [ showAlbumInfo, setShowAlbumInfo ] = useState(false)
    const [ hideCopy, setHideCopy ] = useState(false)
    const [ albumNav, setAlbumNav ] = useState(0)
    const [trkNum, setTrkNum ] = useState(0)


      const [ playAlbum, setPlayAlbum ] = useState(albums[albumNav])
      const  album = albums[albumNav]
      const [ playAudio, setPlayAudio] = useState(albums[albumNav]?.tracks?.[trkNum])
      const [ audioObj, setAudioObj ] = useState(albums[albumNav]?.tracks?.[trkNum])
      const [ showHeader, setShowHeader ] = useState(true)
      const [ headerText, setHeaderText ] = useState(`${album?.artist} - ${audioObj?.name}`)
      const { height, width } = useWindowDimensions();
      const [ currentComment, setCurrentComment ] = useState('')

      const [ comments, setComments ] = useState('')
      const [ showComments, setShowComments ] = useState(false)



        useEffect(() => {
          const checkForComment = () =>{
             playAudio?.comments?.forEach((c,i)=>{
               if( currentTime >= c.time -5 && currentTime <= c.time +5 ){ 
                setShowComments(true)
                setCurrentComment(c)
                console.log('Comment Popup',c)
                return c
              }else{
                setCurrentComment('')
                setShowComments(false)

              }
            })
     
          }
          checkForComment()
      }, [currentTime]);

      
     

    // const  albumList = albums
  const player = useRef()

  const albumStatusList ={
        'jam':'#9E9E9E',
        'demo':'#9E9E9E',
        'b-side':'#9E9E9E',
        'instrumental':'#FFC107',
        'writing':'#FFEB3B',
        'recording':'#03A9F4',
        'mixing':'#3F51B5',
        'mastering':'#3F51B5',
        'complete':'#CDDC39',
        'touring':'#CDDC39',
        'pre-sale':'#CDDC39',
        'streaming':'#CDDC39',
    }

    const brands = ['facebook','instagram','linkedin','upwork', 'github', 'gitlab', 'twitter', 'bandcamp','soundcloud','spotify','youtube','vimeo','amazon','tidal','youTube',
        'airbnb','uber','lyft','stackoverflow','stackexchange','pinterest','skype','etsy','codepen', 'dropbox', 'googleplay','imdb','npm','meetup','itunes','cashApp','venmo','paypal','pandora','deezer','linktree','xbox','playstation','steam','snapchat','slack','kickstarter','patreon','stripe', 
        'discord','wordpress','tiktok','whatsapp',
        'windows','apple','shopify','twitch','tumblr','trello','unity','wikipedia'
    ]


  const [deviceInfo, setDeviceInfo] = useState({
      userAgent: '',
      platform: '',
      language: '',
      vendor: ''
    });

  useEffect(() => {
    const info = {
      userAgent: navigator.userAgent,
      platform: navigator.platform,
      language: navigator.language,
      vendor: navigator.vendor
    };
    setDeviceInfo(info);
    // console.log('nav info:',info)
  }, []);
 
  
  const colors ={
    main:'#858585',
    accent: '#000',
  }
//  const artist_pic= 'https://res.cloudinary.com/dzxzdsnha/image/upload/v1707683707/Rex_Entropy_Logo_Flat_LG_BLK_zuc1wp.png'
   
   const containerStyles = {
        position:'absolute',
        display:'flex',
        flexDirection:'column',
        height:'100%',
        // maxHeight:'800px',
        maxHeight:'95vh',
        width:'100%',
        maxWidth:'600px',
        // fontSize: '0.75rem',
        fontFamily:'Gilroy,sans-serif',
        border:`2px solid ${colors?.main}`,
        borderRadius: '10px',
        padding:'0.5rem',
        marginBottom:'15rem',
        boxShadow:'0px 0px 5px 2px rgba(0, 0, 0, 0.3)',
        // overflow:'hidden',
        // overflowY:'scroll',
    }
  const bannerStyles = {
    position:'relative',
    height: '20vh',
    minHeight:'200px',
    minWidth: '300px',
    width:'100%',
    border: `1px  solid ${colors?.main}`,
    // boxShadow:'0 0 3px 2px rgba( 0, 0, 0, 0.1)',
    borderRadius: '10px 10px 0px 0px',
    backgroundColor:'red',
    marginTop: '0.5rem',
    boxShadow:'inset 0px -5px 11px 5px rgba(255,255,255,0.5),0px -5px 5px 2px rgba(0,0,0,0.1)',
    backgroundImage:`url("${album?.album_pic[0]}")`,//backgroundAttachment:'fixed',
    backgroundRepeat: 'no-repeat',
    backgroundPositionY:`${album?.album_pic[2]}px`,
    backgroundSize: 'cover',
    backgroundPosition:`fixed`,

  }
      const linkIconCheck = (l) =>{
        // console.log('[linkIconCheck] brands?.some((k)=>l?.includes(k.toString))',brands?.find((k)=>l?.includes(k?.toString())))
        let result = brands?.find((k)=>l?.includes(k?.toString()))
        if(result){
            return result?.charAt(0).toUpperCase() + result?.slice(1);
        }else if(l?.includes('youtu.be')){
          return 'Youtube'
        }else{ return false}
    }

    const handleCanPlay = () => {
    setIsLoading(false);
  };

  const handleWaiting = () => {
    setIsLoading(true);
  };

    const toggleAudio = () =>{
      player.current.audio.current.play();
    }

    function validateEmail(email) {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return regex.test(email);
    }
    const getUserLocation = async () => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
             async (position) => {
              console.log('[getUserLocation] position:',position)
              
              const { latitude, longitude } = position.coords;
              setUserLocation({ lat:latitude, lon:longitude });
              // updateAppData({
                //     ...appData,
                //     userLocation,
                // })
                // Send location to backend to find nearby businesses
                // const p = await API.fetchNearbyPlaces(latitude, longitude);
                
              },
              (error) => {
                // setError('Error getting user location: ' + error.message);
                console.log('Error getting user location: ' + error.message)
              },
          {
              enableHighAccuracy: true,
              timeout: 10000,
              maximumAge: 0
          }
      );
    }
  }
  const setURLTrack = async () =>{
    try{
      const urlAlbum = albums?.find((album)=>album?.tracks?.some(track => track._id === trackId)) 
      // const urlAlbum = albums?.filter((album)=>album?.tracks?._id === trackId) 
      const urlTrack = urlAlbum?.tracks?.find((track)=> track?._id === trackId ) 

      setPlayAlbum(urlAlbum)

      setAlbumNav(albums?.indexOf(playAlbum))
      setTrkNum(playAlbum?.tracks?.indexOf(urlTrack))
      setPlayAudio(urlTrack)
     
      // console.log('[setURLTrack] urlAlbum:', urlAlbum)
      // console.log('[setURLTrack] urlTrack:', urlTrack)
      // console.log('[setURLTrack] trkNum:', trkNum)
      // console.log('[setURLTrack] playAlbum:',playAlbum)
      // console.log('[setURLTrack] playAudio:',playAudio)
    }catch(err){console.log('[setURLTrack]',err)}
  }
  
  const checkForListener = async () =>{
    try{
      const res = await API.fetchListener(email)

      if (res){
        // console.log('[checkForListener] res:', res)
        setListener(res)
        localStorage.setItem('listener', JSON.stringify(res))   
      }else if (!res){
        const newList =  await API.createListener(email)
        if(newList){
          // console.log('[checkForListener] pre- create:', newList)
          setListener(newList)
          localStorage.setItem('listener', JSON.stringify(newList))
          }
        
        }
      
      }catch(err){console.log('[saveListen]',err)}
  }

    const saveListen = async (clicked,type) =>{
      console.log('[Clickd Type]', clicked , type )
      let duration = playAlbum?.tracks[trkNum]?.duration
      if (clicked.click){
        duration = currentTime
      }
      try{
        const resp = await API.createListen({
          email:email,
          listen:{
            click: type.toString(),
            track:  playAlbum?.tracks[trkNum]?._id,
            title:  playAlbum?.tracks[trkNum]?.name,
            duration: duration,
            coordinates: [userLocation?.lat,userLocation?.lon],
            // ip: ip,
          }
        })
      }catch(err){console.log('[saveListen]',err)}
      
    }

    const handleTrackNext = (click) =>{
          saveListen(click, click?'next':'play through')
          try{
            // setTrkNum((trkNum + 1) % playAlbum?.tracks?.length);
            // setAudioObj(playAlbum?.tracks[trkNum+1])
            // setPlayAudio(playAlbum?.tracks[trkNum+1])
            if( trkNum < playAlbum?.tracks?.length -1 ){
              setTrkNum(trkNum+1)
              setAudioObj(playAlbum?.tracks[trkNum+1])
            setPlayAudio(playAlbum?.tracks[trkNum+1])
          }else{
              setTrkNum(0)
                setAudioObj(playAlbum?.tracks[0])
            setPlayAudio(playAlbum?.tracks[0])
          }
        }catch(err){console.log('[Next Error]:',err)}
      }
    const handleTrackBack = (click) =>{
      saveListen(click,'back')
         try{
            //  setTrkNum(( trkNum - 1 + playAlbum?.tracks?.length) % playAlbum?.tracks?.length);
            //  setAudioObj(playAlbum?.tracks[trkNum-1])
            //  setPlayAudio(playAlbum?.tracks[trkNum-1])
            if( trkNum >0 ){
              setTrkNum(trkNum-1)
              setAudioObj(playAlbum?.tracks[trkNum-1])
              setPlayAudio(playAlbum?.tracks[trkNum-1])
            }else{
              setTrkNum(playAlbum?.tracks?.length - 1 )
                setAudioObj(playAlbum?.tracks[playAlbum?.tracks?.length - 1])
              setPlayAudio(playAlbum?.tracks[playAlbum?.tracks?.length - 1])
            }
          }catch(err){console.log('[Back Error]:',err)}
      }

      

      const handleListen = () => {
        if (player.current) {
          setCurrentTime(player.current.audio.current.currentTime);
        }
      };

       useEffect(() => {
        if (('mediaSession' in navigator )&& playAudio) {
          navigator.mediaSession.metadata = new window.MediaMetadata({
            title: `${playAlbum?.artist} - ${playAudio?.subtitle ?  playAudio?.name +' '+ playAudio?.subtitle : playAudio?.name }`,
            artist: playAlbum?.artist,
            album: playAlbum?.name,
            artwork: [
              { src: playAlbum?.album_pic, sizes: '512x512', type: 'image/png' }
            ]
          });

          navigator.mediaSession.setActionHandler('previoustrack', () => {
            // settrkNum((trkNum - 1 + tracks.length) % tracks.length);
            handleTrackBack({click:true})
          });
          
          navigator.mediaSession.setActionHandler('nexttrack', () => {
            handleTrackNext({click:true})
            // settrkNum((trkNum + 1) % tracks.length);
          });
        }
      }, [trkNum]);



      useEffect(() => {
        console.log('[Checking for listener]');
        if (email) {
          checkForListener(email);
        }
      }, [email]);

      useEffect(() => {
        console.log('[Checking for listener]');
        if (trackId) {
          setURLTrack(trackId);
        }
      }, [trackId]);


    useEffect(() => {
        const handleBeforeUnload = (event) => {
          event.preventDefault();
          saveListen(true,'page leave')
          event.returnValue = ''; // This is required for some browsers to show the alert
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };
      }, []);

      useEffect (()=>{

      },[audioObj])

      useEffect (()=>{
       
        console.log('listener:', listener)
      },[listener])

      useEffect (()=>{

        console.log('playAudio:', playAudio)
      },[playAlbum, playAudio,trkNum])

      useEffect (()=>{

      },[showHeader])
      
     
      useEffect (()=>{

        getUserLocation()

      },[])

//         if (loading) {return <div><h2>sorry</h2></div>;}
// else if(!loading && albums){
if(!validateEmail(email) && !listener?.isVerified){
  return (<LockoutModal lock={validateEmail(email)?false:true} />)
}
else{

  return (
    <Container
      className='center'
      sx={containerStyles}
      >
        
        <LockoutModal lock={validateEmail(email)?false:true} />
        <UpdateModal 
          email={email}
          setListener={setListener}
          listener={listener}
          updateModalOpen={updateModalOpen} 
          setUpdateModalOpen={setUpdateModalOpen} />
    
        <Tooltip title='share'>
          <Box sx={{
            display:'flex',
              borderRadius:'5px',
              width:'2rem',
              height:'3rem',
              alignContent:'center',
              alignItems:'center',
              justifyContent:'center',
              padding:'0.125rem',
              border:'1px solid #757575',
              boxShadow:'0 0 3px 2px rgba( 0, 0, 0, 0.1), inset 0 0 3px 1px rgba( 0, 0,0,0.3)',
              '&:hover': {background:'rgba(144, 254, 195,0.5)'},
            '&:active': {boxShadow:'inset 0 0 3px 2px rgba( 0, 0, 0, 0.1)'},
              }}>
            <ShareIcon 
            className='button-woddle'
            sx={{cursor:'pointer'}}
            onClick={()=>{
              setShareModalOpen(true)
            }} />
          </Box>
        </Tooltip >
        <CommentModal
            setCommentModalOpen={setCommentModalOpen} 
            commentModalOpen={commentModalOpen}
            track={playAlbum?.tracks[trkNum]}
            playAlbum={playAlbum}
            playAudio={playAudio}
            timestamp={timestamp}
            trkNum={trkNum}
            listener={listener}
          />
        
        <ShareModal 
          listener={listener}
          setListener={setListener}
          shareModalOpen={shareModalOpen} 
          validateEmail={validateEmail}
          setShareModalOpen={setShareModalOpen}/>
         {/* <FontAwesomeIcon icon={"fa-solid fa-crown"} /> */}
              {/* <Box 
               sx={{position:'absolute', top:'0.25rem', left:'50%',
                transform:'rotate(-17deg) translate(-37%,-80%)',width:'4rem', height:'4rem',
                backgroundColor:'#FFF',
                border:'2px solid #000', borderRadius:'50%', justifyContent:'center',
                alignItems:'center',
                boxShadow:'0px 0px 5px 2px rgba(0, 0, 0, 0.3)'
              }}
              >
              <Box  sx={{position:'absolute',left:'50%',top:'50%',
              transform:'translate(-50%,-40%)',
              width:'4rem', height:'4rem'
              // width:'100%', height:'100%'

              }}>

              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M309 106c11.4-7 19-19.7 19-34c0-22.1-17.9-40-40-40s-40 17.9-40 40c0 14.4 7.6 27 19 34L209.7 220.6c-9.1 18.2-32.7 23.4-48.6 10.7L72 160c5-6.7 8-15 8-24c0-22.1-17.9-40-40-40S0 113.9 0 136s17.9 40 40 40c.2 0 .5 0 .7 0L86.4 427.4c5.5 30.4 32 52.6 63 52.6l277.2 0c30.9 0 57.4-22.1 63-52.6L535.3 176c.2 0 .5 0 .7 0c22.1 0 40-17.9 40-40s-17.9-40-40-40s-40 17.9-40 40c0 9 3 17.3 8 24l-89.1 71.3c-15.9 12.7-39.5 7.5-48.6-10.7L309 106z"/></svg>
              </Box>
            <Stack sx={{color:'#FFF', gap:'0rem', position:'relative', left:'0',top:'45%'}}>
              <b>
                Rex
              </b>
              <p style={{marginTop:'-0.5rem'}}>
                Player
              </p>
            </Stack>
              </Box> */}
              <Stack sx={{position:'sticky', top:'-1rem',
              
               width:'40vw', height:'40vh',
          // border:'1px solid red',
          margin:'auto',marginBottom:'1.5rem',padding:'0.5rem',
          justifyContent:'center', alignContent:'center',alignItems:'center'
          }}>
        <Stack sx={{border:`1px solid ${colors.main}`, 
            position:'absolute',
            top:'-3.5rem', zIndex:999,
            backgroundColor:'#FFF',
            width:'fit-content', margin:'auto',
            color: colors.accent,
            cursor:'pointer',
            boxShadow:'0 0 3px 2px rgba( 0, 0, 0, 0.1), inset 0 0 3px 1px rgba( 0, 0,0,0.3)',
            '&:hover': {background:'rgba(144, 254, 195,0.5)'},
            '&:active': {boxShadow:'inset 0 0 3px 2px rgba( 0, 0, 0, 0.1)'},
            borderRadius:'5px'}}
            onClick={()=>{
              setUpdateModalOpen(true)
            }}
            >
              <span style={{fontSize:'0.8rem',borderBottom:`1px solid ${colors.main}`,
                padding:' 0.125rem 0.25rem',}}>
                Welcome, <b>{listener?.preferredName? listener?.preferredName: email}!</b>
              </span>
              <b style={{fontSize:'0.75rem',backgroundColor: colors.accent,color:'#FFF',padding:'0.25rem'}}>
                click to change name
              </b>
        </Stack>
         <Stack sx={{height:'100px',position:'relative'}}>
            <Box component={'img'}
                    className='lock-logo'
                    src={`/assets/Rex_Entropy_Icon_Glow.png`}
                    sx={{width:'100%', 
                      maxWidth:'100px',
                      height:'auto', 
                      // opacity:'0.5',
                      border:'3px solid #000',
                      borderRadius:'50%',
                      backdropFilter:'blur(5px)',
                      backgroundColor:'#000',
                      boxShadow:'0 0 10px 4px rgba( 0,0, 0,0.2), inset 0 0 10px 3px rgba( 255, 255,255,0.3)',
                      position:'relative',
                      zIndex:0,
                    }}
                        ></Box>
                      
                  <span style={{ position:'absolute',
                      bottom:'-1rem',
                      left:'50%',
                      transform:'translate(-50%,0)',
                      display:'flex',color:'#000' ,
                      backgroundColor:'#FFF',fontFamily:'Gilroy,sans-serif',
                      width:'fit-content',
                       boxShadow:'0 0 3px 2px rgba( 0, 0, 0, 0.1), inset 0 0 3px 1px rgba( 0, 0,0,0.3)',
                      border:'2px solid #000', borderRadius:'20px',padding:'0.5rem' }}>
                  <b style={{ position:'absolute',left:'2rem', top:'0.25rem'}}>
                    The 
                  </b>
                  <h1>RexPlayer</h1>
                  </span>
            </Stack>
         </Stack>
       
         <PinModal pinModalOpen={pinModalOpen} setPinModalOpen={setPinModalOpen} item={album}/>
       
       
        <Box sx={{display:'flex', margin:'auto', gap:'0.5rem',}}>
          {albums?.map((album,i)=>{

            return (
              <Tooltip title={album?.title} >
                <Box 
                  key={i}
                  component='img'
                  src={album?.album_pic[0]}
                  sx={{width:'50px', height:'50px', borderRadius:'5px',cursor:'pointer',
                    border:`1px solid ${albumNav === i ? '#757575':'#000'}`,cursor:'pointer',
                  boxShadow: albumNav === i ?'0px 0px 7px 2px rgba(0,0,0,0.2)':''
                  }}
                  onClick={()=>{  
                    setAlbumNav(i)
                  }}
                  >
                </Box>
              </Tooltip>
            )
          })}

        </Box>

        <Box  sx={{cursor:'pointer',
            position:'absolute',right:'0.5rem',top:'5rem'}} 
         onClick={()=>{
            try{
              console.log('[current Time]:', currentTime)
              setTimestamp(currentTime)
              setCommentModalOpen(true)
            }catch(err){console.log(err)}
                }}  >

              <AddComment 
               />
        </Box>

        
        
        {( !showHeader && showComments ) && <Box  sx={{position:'absolute', left:'50%',top:'21vh', 
              transform:'translate(-50%,20%)',maxWidth:'80%', width:'fit-content', height:'fit-content',
                padding:'0.5rem', boxShadow:'0 0 3px 2px rgba( 0, 0, 0, 0.1), inset 0 0 3px 1px rgba( 0, 0,0,0.3)',
                border:'2px solid #000',borderBottom:'none', borderRadius:'5px 5px 0 0',
                backgroundColor:'#FFF', textAlign:'center',alignContent:'center',
                zIndex:9999,
              }}>
                <Typography variant="body1">
                  {currentComment.comment}
                </Typography>
                <Typography variant="caption">
                  {`At ${currentComment?.time}s by ${currentComment?.listener?.name}`}
                </Typography>
          {/* {currentComment} */}

        </Box>}
         
        {/* <section style={{position:'sticky',top:0,left:0,height:'100%'}}> */}

              {!showHeader&&<ArrowDropUpIcon
                  className='button-woddle'
                  sx={{color:'#FFF', 
                  border:`7px solid ${showHeader ? '#000':'#FFF'}`, 
                  borderRadius:'50%',
                  backgroundColor:colors.accent,
                  boxShadow:'inset 0 0 3px 1px rgba( 255, 255,255,0.5)',
                  position:'relative',
                  top:'-1rem',
                  left:'-0.125rem',
                  cursor:'pointer',
                  zIndex:9999
                }}
                onClick={()=>{
                  setShowHeader(!showHeader)
                }}
                />
            
              // <Box sx={{position:'absolute', bottom:'-1rem',
              //     right:'-0.125rem',
              //     }}>
              // <ArrowDropDownIcon onClick={()=>{setShowHeader(!showHeader)}}/>
              // </Box>
              }

          {showHeader && 
          <Box sx={bannerStyles}
          // src={artist_pic}
          >
            <ZoomInIcon
                className='button-woddle'
                sx={{color:'#FFF', 
                  fontSize:'0.75rem',
                border:'3px solid #FFF', 
                borderRadius:'50%',
                backgroundColor:colors.accent,
                position:'absolute',
                bottom:'0.5rem',
                left:'0.5rem',
                cursor:'pointer',
                opacity:'0.75',
                padding:'0.2rem',
                zIndex: 9999
              }}
              onClick={()=>{
                setZoomModalOpen(true)
                console.log('showHeader:',showHeader)
              }}
              />
                <ZoomModal setZoomModalOpen={setZoomModalOpen} zoomModalOpen={zoomModalOpen} src={album?.album_pic[0]}/>

              {showHeader?<ArrowDropDownIcon
                  className='button-woddle'
                  sx={{color:'#FFF', 
                  border:'7px solid #FFF', 
                  borderRadius:'50%',
                  backgroundColor:colors.accent,
                  position:'absolute',
                  top:'-1rem',
                  left:'-0.125rem',
                  cursor:'pointer',
                  zIndex: 9999
                }}
                onClick={()=>{
                  setShowHeader(!showHeader)
                  console.log('showHeader:',showHeader)
                }}
                />
              :
              <Box sx={{position:'absolute', bottom:'-1rem',
                  right:'-0.125rem',
                  }}>
              <ArrowDropUpIcon onClick={()=>{setShowHeader(!showHeader)}}/>
              </Box>
              }
              <Stack sx={{gap:'0.25rem', 
                height:'5rem',
                borderRadius:'10px 10px 0 0',margin:'auto',
                padding:'0.25rem',
                boxShadow:'inset 0 0 5px 1px rgba( 0,0,0,0.2), 0px 2px 5px 1px rgba(0,0,0,0.2) ',
                backgroundColor:'rgba(255,255,255,1)',
                backdropFilter:'blur(5px)'}}>
                <Typography  sx={{fontFamily:'Gilroy, sans-serif', fontSize:'1rem',fontWeight:'bold',margin:'0 auto'}}>
                  {album?.title}  
                </Typography>
                <Typography  sx={{fontFamily:'Gilroy, sans-serif', fontSize:'0.8rem',fontWeight:'normal', marginTop: '-0.5rem'}}>
                  {album?.subtitle}  
                </Typography>
                <Typography  sx={{fontFamily:'Gilroy, sans-serif', fontSize:'0.8rem',fontWeight:'normal', marginTop: '-0.5rem'}}>
                  {album?.year}  
                </Typography>
              </Stack>
        {/* <Box  sx={{color:'#FFF', 
             
              borderRadius:'10px',
              backgroundColor:colors.accent,
              cursor:'pointer'}}> */}
                <Typography variant='p'
                  sx={{color: albumStatusList[albums[albumNav]?.status], 
                      position:'absolute',
                      top:'3rem',
                      left:'0.5rem',
                      padding:'0.125rem 0.125rem',
                      width:'fit-content',
                      minWidth: '100px',
                      fontSize:'0.8rem',
                      // backgroundColor:'rgba(176,255,252,0.5)',
                    
                      // backdropFilter:'blur(5px)',
                      backgroundColor:'rgba(255,255,252,1)',
                      boxShadow:`inset 0 0 3px 1px ${albumStatusList[albums[albumNav]?.status]}80,0px 0px 5px 2px ${albumStatusList[albums[albumNav]?.status]}40`,
                      border:`1px solid ${albumStatusList[albums[albumNav]?.status]}`, 
                      borderRadius:'10px'}}>
                        <b>status</b> | <span style={{color:"#000"}}>{albums[albumNav]?.status}</span>
                  </Typography>
        <Stack 
          sx={{
          position:'absolute',
          top:'1rem',
          right: '0.5rem',
          gap:'0.25rem',
            fontSize:'0.75rem', alignItems:'center', cursor:'pointer'}}
             onClick={(e)=>{
                setPinModalOpen(true)
            }}
            >
             

            <ShoppingCart 
                className='button-woddle'
                sx={{fontSize:'1rem', color:colors?.main}}
                onClick={()=>{
                  setPinModalOpen(true)
                }}

                />
           $ {album?.price} USD
          
        </Stack>
        {showComments &&<Box  sx={{position:'absolute', left:'50%',bottom:'-0.25rem', 
              transform:'translate(-50%,0%)',maxWidth:'80%', width:'fit-content', height:'fit-content',
                padding:'0.5rem', boxShadow:'0 -2px 3px 1px rgba( 0, 0, 0, 0.2), inset 0 0 3px 1px rgba( 0, 0,0,0.3)',
                border:'2px solid #000',borderBottom:'none', borderRadius:'5px 5px 0 0',
                backgroundColor:'#FFF', textAlign:'center',alignContent:'center',
                zIndex:9999,
              }}>
                <Typography variant="body1">
                  {currentComment.comment}
                </Typography>
                <Typography variant="caption">
                  {`At ${currentComment?.time}s by ${currentComment?.listener?.name}`}
                </Typography>
          {/* {currentComment} */}

        </Box>}

         {/* { (comment.time <= currentTime-5 || comment.time< currentTime+5 ) &&  */}
         {/* } */}

         {/* {showComments && ( */}
            {/* <List sx={{position:'absolute', left:'50%',top:'50%', 
              transform:'translate(-50%,0%)',width:'80%', minHeight:'100%',
                padding:'0.5rem',
                border:'2px solid #000',borderTop:'none', borderRadius:'5px 5px 0 0',
                backgroundColor:'#FFF', textAlign:'center',alignContent:'center',
                zIndex:9999,
              }}>

              {playAudio?.comments.map((comment, index) =>{
                return (
                  <ListItem key={index}>
                      <ListItemText
                        primary={<Typography variant="body1">{comment.comment}</Typography>}
                        secondary={
                          <Typography variant="caption">
                            {`At ${comment.time}s by ${comment.listener.name}`}
                          </Typography>
                        }
                      />
                    </ListItem>
                    )
                    })
                  }
            </List> */}
          {/* )} */}

        {/* </Box> */}
            {!showAlbumInfo?<InfoIcon 
              className='button-woddle'
              sx={{color:'#FFF', 
              border:'7px solid #FFF', 
              borderRadius:'50%',
              backgroundColor:colors.accent,
              position:'absolute',
              bottom:'-1rem',
              right:'-0.125rem',
              cursor:'pointer',
            }}
            onClick={()=>{
              setShowAlbumInfo(true)
            }}
            />
          :
          <Box sx={{position:'absolute', bottom:'-1rem',
              right:'-0.125rem',
              }}>
          <CloseButton func={setShowAlbumInfo}/>
          </Box>
          }
          </Box>}

          {showAlbumInfo &&<Box sx={{minHeight:'20vh', maxHeight:'50vh',
            overflowY:'scroll',padding:'0.5rem',
            justifyContent:'center', alignContent:'center'}}>
            {album?.info}
          </Box>}
         {/* {showComments && <Box sx={{position:'absolute', left:'50%',top:'50%', 
          transform:'translate(-50%,0%)',width:'80%', minHeight:'100%',
            padding:'0.5rem',
            border:'2px solid #000',borderBottom:'none', borderRadius:'5px 5px 0 0',
            backgroundColor:'#FFF', textAlign:'center',alignContent:'center',
          }}> */}
            {/* {playAudio?.comments[1]?.comment} */}
            {/* {currentComment} */}
               {/* {showComments ? "Hide Comments" : "Show Comments"} */}
  
     
          {/* </Box>} */}

        <AudioPlayer
          ref={player}
          onListen={handleListen}
          listenInterval={1000}
          className="player"
          onEnded={()=>handleTrackNext({click:false})}
          onClickNext={()=>handleTrackNext({click:true})}
          onClickPrevious={()=>handleTrackBack({click:true})}
          onCanPlay={handleCanPlay}
          onWaiting={handleWaiting}
          autoPlayAfterSrcChange 
          // src={playAudio?.track_url}
          src={playAlbum?.tracks?.length ? playAlbum?.tracks[trkNum]?.track_url:''}
          showJumpControls={false}
          // layout="stacked-reverse"
          layout="stacked"
          // customProgressBarSection={[]}
          // customControlsSection={["MAIN_CONTROLS", "VOLUME_CONTROLS"]}
    
          muted={isMuted}
          showSkipControls
          showDownloadProgress ={true}
          // crossOrigin = {'anonymous'}
          crossOrigin = {window?.location?.href?.includes('://localhost:')?false:'anonymous'}
          onError={e => console.log("[ -----Audio------- ]Error: ",e)}
          header={playAlbum ? `${playAlbum?.artist} - ${playAudio?.subtitle ?  playAudio?.name +' '+ playAudio?.subtitle : playAudio?.name }`:`Select a track!`}
          // header={audioObj?.name ? headerText : album?.artist}
          style={{borderRadius:'0 0 10px 10px'}}


        />
       
    
  {isLoading && <p style={{margin:'auto'}}>Loading...</p>}  
          <Playlist album={album} 
            duration={currentTime}
            saveListen={saveListen}
            setAudioObj={setAudioObj}
            audioObj={audioObj}
            playAudio={playAudio}
            toggleAudio={toggleAudio}
            setPlayAudio={setPlayAudio}
            playAlbum={playAlbum}
            setPlayAlbum={setPlayAlbum}
            albums={albums}
            setTrkNum={setTrkNum}
            trkNum={trkNum}
            albumNav={albumNav}
            setAlbumNav={setAlbumNav}
            listEmail={email}
            colors={colors} 
            listener={listener}
            setListener={setListener}
            />
            
        {/* </section> */}
       <Box sx={{
        display:'flex',
        flexDirection:'column',
         position:'fixed',
         bottom:'0',
         left:'50%',
         transform:'translate(-50%,20%)',
         width:'80%',
         height:hideCopy? '0':'8.5rem'
        }}>

         <Box sx={{position:'relative',alignSelf:'start',width:'100%', }} onClick={()=>{
           setHideCopy(!hideCopy)
         }}>

          {!hideCopy ? 
          <Box sx={{position:'absolute',left:'0.5rem',top:'0.5rem', zIndex:999}}>
            <CloseButton /> 
          </Box>
          : <Box sx={{
            display:'flex',
            position:'absolute',
            bottom:'-2px',
            left:'50%',
            transform:'translate(-50%,0)',
            border:'2px solid #858585',
            padding:'0.25rem 1.25rem',
            backgroundColor:'#fff',cursor:'pointer',
            alignItems:'center',
            boxShadow: '0px -1px 7px 2px rgba(0,0,0,0.2)',
            borderRadius:'5px 5px 0px 0px'}}>
              <ArrowDropUpIcon />
            about us
            </Box>}
         </Box>
         
         {!hideCopy && <Copyright view='signupSheet' 
         hide={hideCopy}
         color={colors.main}
         shadow={true}
         /> }
        </Box>
    </Container>
  )
}
}
// }

export default ShopMusicPlayer